import { Component, OnInit, OnDestroy, ViewChild, HostListener, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { Subscription } from 'rxjs';
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { BreadcrumbService} from 'ng5-breadcrumb';

import PerfectScrollbar from 'perfect-scrollbar';
import { TourService,IStepOption } from 'ngx-tour-ng-bootstrap';
import { filter } from 'rxjs/operators';
import { UserService } from 'app/services/user.service';
import { MasterService } from '../services/master.service';
import { MenuItems } from '../core/menu/menu-items/menu-items';
import { PageTitleService } from '../core/page-title/page-title.service';
// import { AuthService } from '../service/auth/auth.service';
// import { CoreService } from '../service/core/core-service.service';
import { LogintypeService } from 'app/services/logintype.service';
import { TokenService } from 'app/services/token.service';

declare var require: any
declare var $ : any;
const screenfull = require('screenfull');

@Component({
	selector: 'chankya-layout',
	templateUrl: './main-material.html',
	styleUrls: ['./main-material.scss'],
	encapsulation: ViewEncapsulation.None
})
 
export class MainComponent implements OnInit, OnDestroy {

	private _router: Subscription;
    header: string;
    currentLang = 'en';
    url: string;
    showSettings = false;
    themeSkinColor: any = "light";
    dark: boolean;
    boxed: boolean;
    collapseSidebar: boolean;
    compactSidebar: boolean;
    customizerIn: boolean = false;
    chatWindowOpen: boolean = false;
    chatSidebar: boolean = false;
    sidebarClosed: boolean = false;
    root = 'ltr';
    chatpanelOpen: boolean = false;
    layout : any = 'ltr';
    isValid=true;
    role:string;
    private _mediaSubscription: Subscription;
    sidenavOpen: boolean = true;
    sidenavMode: string = 'side';
    isMobile: boolean = false;
    private _routerEventsSubscription: Subscription;
    public innerWidth: any;
    fullname:string;
    institutionname:string;
    schoolname:[''];
    systemadmin:string;
    actualname:string;
    insitutename:string;
    image:string;
    profilepic:string;
    schoollength : any;
    institutionlength:any;
    partnertype:any;
    @ViewChild('sidenav') sidenav;
    imageUrl = '';
    showData: any;
    
    _opened: boolean = true;
    _mode: string = "push";
    _closeOnClickOutside: boolean = false;
    _showBackdrop: boolean = false;
 
    // public _toggleOpened(): void {
    //     this._opened = !this._opened;
    //  }
   userid;
   userID
	constructor(public tourService: TourService,
		            public userService:UserService,
                    public menuItems: MenuItems, 
                    private breadcrumbService: BreadcrumbService, 
                    private pageTitleService: PageTitleService,
                    public translate: TranslateService, 
                    private router: Router, 
					private media: MediaObserver,
					public masterService: MasterService,
					public logintype: LogintypeService,
          public TokenService : TokenService,

					) {
			
			this.url = masterService.url;
            this.imageUrl = masterService.imageurl;
        const browserLang: string = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

		breadcrumbService.addFriendlyNameForRoute('/dashboard', 'Dashboard');
        breadcrumbService.addFriendlyNameForRoute('/dashboard/dashboard-v1', 'Dashboard V1');
        breadcrumbService.addFriendlyNameForRoute('/session', 'Session');
        breadcrumbService.addFriendlyNameForRoute('/session/login', 'Login');
        breadcrumbService.addFriendlyNameForRoute('/session/register', 'Register');
        breadcrumbService.addFriendlyNameForRoute('/session/forgot-password', 'Forgot');
        breadcrumbService.addFriendlyNameForRoute('/session/lockscreen', 'Lock Screen');
	  
}
	ngOnInit() {

    this.TokenService.checkuser().subscribe((data:any)=>{
      console.log('checkuser r',data);
      if(data.status_code == 416)
        {
          localStorage.removeItem('token');
          localStorage.clear();
          this.router.navigate(['login']);
        }
      
    });
		this.innerWidth = window.innerWidth;
        this.pageTitleService.title.subscribe((val: string) => {
            this.header = val;
        });
        const role=localStorage.getItem('role');
        const systemadmin=localStorage.getItem('System Admin');
        const instituteadmin=localStorage.getItem('Institute Admin');
        const schooladmin=localStorage.getItem('School Admin');
        const principal=localStorage.getItem('Principal');
        const teacher=localStorage.getItem('Teacher');
        const student=localStorage.getItem('Student');
        const parent=localStorage.getItem('Parent');
        const accountant=localStorage.getItem('Accountant')
        const partner=localStorage.getItem('Partner')

		this.userService.userinfo().subscribe(data =>{
            // alert(3)
                // if(this.image == null){
                //     console.log(42435464758);
                //     this.image=data['user'].schoolimage[0];
                // 
                console.log('ankita data',data['status_code']);
                if(data['status_code'] == 416)
                {
                  localStorage.removeItem('token');
	                localStorage.clear();
	                this.router.navigate(['login']);
                }
                
                this.userID =  data['user'].id;
                console.log("mainid", this.userID);
                this.userid =   localStorage.setItem('userid',this.userID);
           
                        
            console.log("ssssssssssijjnjnj",this.userid);
             this.partnertype = data['user'].partner_type;
             console.log("ggggggggg", this.partnertype);
                if(data['user'].roles == 'School Admin'){
                    if(data['user'].login_info == null){
                        this.router.navigate(['/dashboard']);
               }
                }
               

             this.fullname = data['user'].firstname + ' '+ data['user'].lastname;
             if(data['user'].img == "" || data['user'].img == null){
                this.profilepic = 'assets/img/profile.png';
                this.userService.profileImage = 'assets/img/profile.png';
             }else{
                this.profilepic = this.imageUrl+'users/'+ data['user'].img;
                this.userService.profileImage = this.imageUrl+'users/'+ data['user'].img;
             }
            
            //  console.log(data);
             if(systemadmin != null){
                this.actualname = 'System Admin';
                // this.schoolname = data['user'].schoolnames;
                this.image='assets/img/systemadmin.jpg';
             }
             if(instituteadmin != null){
                this.actualname = 'Institute Admin';
                this.insitutename = data['user'].institutionname;
               // console.log(this.insitutename[0]);
                this.institutionlength=this.insitutename[0].length;
                this.schoolname = data['user'].schoolnames;
                // this.image='http://192.168.1.237/institution/'+ data['user'].institutionimage[0];
                this.image= this.imageUrl+'institution/'+ data['user'].institutionimage[0];
             }

             if(schooladmin != null){
                this.actualname = 'School Admin';
                this.insitutename = data['user'].institutionname;
                this.schoolname = data['user'].schoolnames;
               
                this.schoollength = this.schoolname[0].length;
               // console.log(this.schoollength);
              //  this.image='http://192.168.1.237/school/'+data['user'].schoolimage[0];
                this.image= this.imageUrl+'school/'+ data['user'].schoolimage[0];
             }

             if(principal != null){
                this.actualname = 'Principal';
                this.schoolname = data['user'].schoolnames;
                this.schoollength = this.schoolname[0].length;
                this.image= this.imageUrl+'school/'+ data['user'].schoolimage[0];
             }
             if(teacher != null){
                this.actualname = 'Teacher';
                this.schoolname = data['user'].schoolnames;
                this.schoollength = this.schoolname[0].length;
                this.image= this.imageUrl+'school/'+ data['user'].schoolimage[0];
             }

             if(accountant != null){
                this.actualname = 'Accountant';
                this.schoolname = data['user'].schoolnames;
                this.schoollength = this.schoolname[0].length;
                this.image= this.imageUrl+'school/'+ data['user'].schoolimage[0];
             }
             if(partner != null){
              this.actualname = 'Partner';
             // this.schoolname = data['user'].schoolnames;
             // this.schoollength = this.schoolname[0].length;
              this.image='assets/img/systemadmin.jpg';
              console.log('image',this.image);
           }
            
            //  console.log(this.schoolname);
             
          });  if(role == 'System Admin'){
			//  this.isValid = true;
			  this.role="1";
		  }
		  if(role == 'Institute Admin'){
			  this.role="2";
		  }
  
		  if(role == 'School Admin'){
			//  this.isValid = false;
			  this.role="3";
		  }
  
		  if(role == 'Principal'){
		   //   this.isValid = false;
			  this.role="4";
		  }
  
		  if(role == 'Teacher'){
			  //   this.isValid = false;
				 this.role="5";
			 }
  
			 if(role == 'Accountant'){
			  //   this.isValid = false;
				 this.role="6";
			 }

       if(role == 'Partner'){
			  //   this.isValid = false;
				 this.role="7";
			 }


          this._router = this.router.events.pipe(
			filter((event: Event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
				this.url = event.url;
			});

		if (this.url != '/session/login' && this.url != '/session/register' && this.url != '/session/forgot-password' && this.url != '/session/lockscreen') {
			const elemSidebar = <HTMLElement>document.querySelector('.sidebar-container');

			/** Perfect scrollbar for sidebar menu **/
			if (window.matchMedia(`(min-width: 960px)`).matches) {
				const ps = new PerfectScrollbar(elemSidebar, {
					wheelSpeed: 2,
					wheelPropagation: true,
					minScrollbarLength: 20
				});
				ps.update();
			}

			/** Perfect scrollbar for chat window **/
			const elemChatbar = <HTMLElement>document.querySelector('.chat-inner ');
			if (window.matchMedia(`(min-width: 1280px)`).matches) {
				const pse = new PerfectScrollbar(elemChatbar);
			}
		}

		if (this.media.isActive('xs') || this.media.isActive('sm') || this.media.isActive('md')) {
			this._mode = 'over';
			this._closeOnClickOutside = true;
			this._showBackdrop = true;
			this._opened = false;
			this.sidebarClosed = false;
		}

		this._mediaSubscription = this.media.media$.subscribe((change: MediaChange) => {
			let isMobile = (change.mqAlias == 'xs') || (change.mqAlias == 'sm')  || (change.mqAlias == 'md');

			this.isMobile = isMobile;
			this._mode = (isMobile) ? 'over' : 'push';
			this._closeOnClickOutside = (isMobile) ? true : false;
			this._showBackdrop = (isMobile) ? true : false;
			this._opened = !isMobile;
			this.sidebarClosed = false;
		});

		this._routerEventsSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd && this.isMobile) {
				this.sidenav.close();
			}
		});

		//Add class on focus of search box in header
		$('.dropdown').on('show.bs.dropdown', function(e){
			$(this).find('.dropdown-menu').first().stop(true, true).slideDown(500);
		  });
  
		  // Add slideUp animation to dropdown
		  $('.dropdown').on('hide.bs.dropdown', function(e){
			$(this).find('.dropdown-menu').first().stop(true, true).slideUp(500);
		  });
  
		  //Add class on focus of search box in header
		  $('.search-form input').focus(function () {
			  $(this).parent().addClass('search-active');
		  }).blur(function () {
			  $(this).parent().removeClass('search-active');
		  });
	  }
	  
	ngOnDestroy() {
		this._router.unsubscribe();
		this._mediaSubscription.unsubscribe();
	}
	isFullscreen: boolean = false;
	menuMouseOver(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && this.collapseSidebar) {
            this._mode = 'over';
        }
    }

    menuMouseOut(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && this.collapseSidebar) {
            this._mode = 'push';
        }
    }
	/**
	  * toggleFullscreen method is used to show a template in fullscreen.
	  */
	toggleFullscreen() {
		if (screenfull.enabled) {
			screenfull.toggle();
			this.isFullscreen = !this.isFullscreen;
		}
	}

	/**
	  * _toggleOpened method is used to toggle a menu list.
	  */
	public _toggleOpened() {
		this._opened = !this._opened;
		this.sidebarClosed = !this.sidebarClosed;
	}

	/**
	  * customizerFunction is used to open and close the customizer.
	  */
	 customizerFunction() {
        this.customizerIn = !this.customizerIn;
    }
     chatWindowFunction() {
        this.chatWindowOpen = !this.chatWindowOpen;
    }
    
    chatSidebarFunction(){
        this.chatSidebar = !this.chatSidebar;
    }
     sidebarClosedFunction(){
        this.sidebarClosed = !this.sidebarClosed;
    }

    changeThemeColor(color){
        this.themeSkinColor = color; 
    }
	/**
	  * addMenuItem is used to add a new menu into menu list.
	  */
	 addMenuItem(): void {
        this.menuItems.add({
            state: 'session',
            name: 'CHANKYA MENU',
            type: 'sub',
            icon: 'icon-plus icons',
            children: [
                {state: 'not-found', name: 'SUB MENU1'},
                {state: 'undermaintance', name: 'SUB MENU2'}
            ]
        });
    }
    onActivate(e, scrollContainer) {
    scrollContainer.scrollTop = 0;
  }

	/**
	  * changeRTL method is used to change the layout of template.
	  */
	 changeRTL(isChecked) {
		if(isChecked){
			this.layout = "rtl"
		} else {
			this.layout = "ltr"
		}
	 }
	 
	 logout(){
	  // alert(1)
	  console.log("logout");
	  localStorage.removeItem('token');
	  localStorage.clear();
	  this.router.navigate(['login']);
	 }
  
  
	 Profile(){
	  //    console.log("profile");
		 this.router.navigate(['/profile']);
		 
	 }
	 
  
}
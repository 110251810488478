import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceapiService } from '../serviceapi.service';

import {Router} from "@angular/router";
import { TokenService } from 'app/services/token.service';
// import { AuthService } from 'app/auth.service';
import { AuthService } from 'app/auth.service';
import { RoleserviceService } from 'app/services/roleservice.service';
import { UserService } from 'app/services/user.service';
import { MasterService } from 'app/services/master.service';
import { ToastrMessageService } from 'app/services/toastr-message.service';
import { PartnerService } from 'app/services/partner.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  form: FormGroup;
  show:boolean = true;
  hide:boolean  = false;

  public username;
 
  constructor(
    public PartnerService :PartnerService,
    public formBuilder: FormBuilder,
    public svcService: ServiceapiService,
    private token: TokenService,
    public router: Router,
    public auth:AuthService,
    public role:RoleserviceService,
    public userService: UserService,
    public masterService: MasterService,
    public toastr: ToastrMessageService,
    public TokenService : TokenService,

  ) { }

  ngOnInit(): void {

    this.TokenService.checkuser().subscribe((data:any)=>{
      console.log('checkuser r',data);
      if(data.status_code == 416)
        {
          localStorage.removeItem('token');
          localStorage.clear();
          this.router.navigate(['login']);
        }
      
    });
    this.form = this.formBuilder.group({
      username: [null, [Validators.required]],
    
  });

  }
  forgotpassword(value){
    this.show = false;
    this.hide = true;
 console.log(value);
 this.PartnerService.ForgotPassword(value).subscribe(data => {
 console.log(data);
 if(data['status_code'] == 403){
  this.show = true;
  this.hide = false;
  return this.toastr.showError(data['data']);
 }
 if(data['status_code'] == 200){
 
  localStorage.setItem('username',value['username']);
  this.router.navigate(['chnageforgotpassword']);
  return this.toastr.showSuccess('OTP sent successfully to register Email !');
 }
})
  }


  Login(){
    this.router.navigate(['login']);
  }
}

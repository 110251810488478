import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceapiService } from '../serviceapi.service';

import {Router} from "@angular/router";
import { TokenService } from 'app/services/token.service';
import { AuthService } from 'app/auth.service';
import { RoleserviceService } from 'app/services/roleservice.service';
import { UserService } from 'app/services/user.service';
import { MasterService } from 'app/services/master.service';
import { ToastrMessageService } from 'app/services/toastr-message.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
  form: FormGroup;
  authenticationFlag: boolean = true;
  type="web";
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  dropdownList2 = [];
  selectedItems2 = [];
  dropdownSettings2 = {};
  item = [];
  item2 = [];
  selectSchool: boolean = false;
  public loginRole = <any>[];
  public loginSchool = <any>[];
  public showhideDiv: boolean = false;
  public password;
  public username;
  public dropdownchange;
  srole;
  classError;
  constructor(public formBuilder: FormBuilder,
    public svcService: ServiceapiService,
    private token: TokenService,
    public router: Router,
    public auth:AuthService,
    public role:RoleserviceService,
    public userService: UserService,
    public masterService: MasterService,
    public toastr: ToastrMessageService,
    
    ) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      username: [null, [Validators.required]],
      password: [null, Validators.required],
      type: []
  });

  if(localStorage.getItem('token')){
    this.router.navigate(['dashboard']);
  }else{
    this.router.navigate(['login']);
  }
// alert(1);

  this.userService.loginRole().subscribe(data => {
    console.log(data)
    this.loginRole = data['data'];
    console.log(this.loginRole)
  })

 

// this.dropdownList = [
//   { id: 1, logintype: 'Principal' },
//   { id: 2, logintype: 'School Admin' },
//   { id: 3, logintype: 'Accountant' },
//   { id: 4, logintype: 'Teacher' },
//   { id: 5, logintype: 'Parent' },
//   { id: 6, logintype: 'Student' }
// ];

this.selectedItems = [
  // { item_id: 3, item_text: 'Pune' },
  // { item_id: 4, item_text: 'Navsari' }
];

this.dropdownSettings = {
  singleSelection: true,
  idField: 'id',
  textField: 'name',
  selectAllText: 'Select All',
  // unSelectAllText: 'UnSelect All',
  itemsShowLimit: 10,
  allowSearchFilter: true,
  closeDropDownOnSelection: true
};



// this.dropdownList2 = [
//   { id: 1, logintype: 'Principal' },
//   { id: 2, logintype: 'School Admin' },
//   { id: 3, logintype: 'Accountant' },
//   { id: 4, logintype: 'Teacher' },
//   { id: 5, logintype: 'Parent' },
//   { id: 6, logintype: 'Student' }
// ];

this.selectedItems2 = [
  // { item_id: 3, item_text: 'Pune' },
  // { item_id: 4, item_text: 'Navsari' }
];

this.dropdownSettings2 = {
  singleSelection: true,
  idField: 'id',
  textField: 'name',
  selectAllText: 'Select All',
  // unSelectAllText: 'UnSelect All',
  itemsShowLimit: 10,
  allowSearchFilter: true,
  closeDropDownOnSelection: true
};

  }



  register(){

    // console.log("register called");
    this.router.navigate(['register']);

  }

  
  forgotpassword(){

    // console.log("forgot-password called");
    this.router.navigate(['forgotpassword']);
  }
  encodeToBase64(data: string): string {
    return btoa(data);
  }
  doLogin=function (user) {

  //  console.log(user);
     console.log('password',user.password);
     user.password = this.encodeToBase64(user.password);
     console.log('encoded',user.password); // Outputs: "SGVsbG8sIEFuZ3VsYXIh"
     user.loginrole = this.item.name;
     if(this.item['name']== "Student") {
      user.school_id = this.item2.id;
     }
   
 
     this.svcService.post('dologin',user,'').subscribe((user) => {
      console.log('resp: ', user);
 
       if(user.status == 'fail'){

           this.authenticationFlag = false;
           this.message= user.message;

 
       }else{
          this.authenticationFlag = true;
          this.handleResponse(user);
       //    localStorage.setItem('token',user.token);
         //  this.router.navigate(['/dashboard']);
 
       }
   });
   
 }

 onItemSelect(item) {
 this.showhideDiv = true;
  // this.item.push(item);
  this.item = item;
  this.srole = item.name.toLowerCase();
  localStorage.setItem('role', this.srole);
  console.log(this.srole);
  if(item) {
    this.username = '';
    this.password = '';
  }

  console.log(this.item);
  if(this.item['name'] == "Student") {
    // alert(1)
    this.selectSchool = true;
    this.showhideDiv = false;
    this.userService.loginSchool().subscribe(data => {
      console.log(data)
      this.loginSchool = data['school'];
      console.log(this.loginSchool)
    })
    console.log(this.loginSchool)
  } else {
    // alert(2)
    this.selectSchool = false;
  }
  if (this.item.length > 0) {
    this.classError = false;
  }
  console.log(this.item);
}

onItemDeSelect(event) {
  this.showhideDiv = false;
  this.selectSchool = false;
  this.username = '';
  this.password = '';
    for (var i = 0; i < this.item.length; i++) {
      if (this.item[i].id === event.id) {
        this.item.splice(i, 1);
      }
    }
}


onItemSelect2(item) {
  this.showhideDiv = true;
  this.item2 = item;
  console.log(this.item2);
}

onItemDeSelect2(event) {
  this.showhideDiv = false;
    for (var i = 0; i < this.item2.length; i++) {
      if (this.item2[i].id === event.id) {
        this.item2.splice(i, 1);
      }
    }
}


 handleResponse(data){
  //  console.log("Ashvini token");
  console.log('yyeyyeyeye');
   console.log('data login',data);
  //  if(data.role[0].name == "Student" ){
  //    alert(5)
  //  }
  this.token.handle(data.token);
  this.auth.changeAuthStatus(true);
   this.role.setrole(data.role);
  // console.log("parentIDS",data.role[0].name)
  // localStorage.setItem('parent',data.role[0].name)
  if(data.role[0].name == "Parent"  ) {
     this.userService.checkparentscorm().subscribe(data => {
      // console.log(data);
      if(data['student_std'] == true && localStorage.getItem('token') ) {
         window.location.replace(this.masterService.imageurl+'scorm/NavitusEducation.html');
      } else {

        
        
        localStorage.removeItem('token');
        localStorage.clear();
        this.router.navigate(['login']);
        this.toastr.showError('9th standard child not present or not allowed to login or the class is inactive');
        setTimeout(function(){
          this.toastr.fadeOut(4000);
      }, 5000);
      }
    })
  } else {
    this.router.navigate(['/dashboard']);
  }
   
  if(data.role[0].name == "Student") {
    this.userService.checkparentscorm().subscribe(data => {
     // console.log(data);
     if(data['student_std'] == true && localStorage.getItem('token') ) {
       window.location.replace(this.masterService.imageurl+'scorm/NavitusEducation.html');
     } else {
      //  alert(2)
       localStorage.removeItem('token');
       localStorage.clear();
       this.router.navigate(['login']);
       this.toastr.showError("9th standard child not present or not allowed to login or the class is inactive");
     }
   })
 } else {
   this.router.navigate(['/dashboard']);
 }
  
}

}



import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import {LoginComponent} from './login/login.component';
import { MainComponent }   from './main/main.component';
import { AuthComponent }   from './auth/auth.component';
//import { ForgotPasswordComponent } from './session/forgot-password/forgot-password.component';
// import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgetPasswordChangeComponent } from './forget-password-change/forget-password-change.component';
import { ProfileComponent } from './profile/profile.component';
import { ConfirmpasswordComponent} from './confirmpassword/confirmpassword.component';
import { FeeReportComponent } from './fee-report/fee-report.component';

export const AppRoutes: Routes = [
   {
  path: '',
  redirectTo: 'login',
  pathMatch: 'full',
   },
   {
      path: 'login', 
      component: LoginComponent 
    },
    {
      // path: 'forgotpassword', 
      // component: ForgotPasswordComponent 
      path: 'forgotpassword', loadChildren: ()=> 
      import('./forgot-password/forgot-password.module').then(m => m.forgotpasswordModule)
    },
    {
      // path: 'confirm-password/:tokenn',
      // component: ConfirmpasswordComponent
      path: 'confirm-password/:tokenn', loadChildren: ()=> 
      import('./confirmpassword/confirmpassword.module').then(m => m.ConfirmPasswordModule)
    },
    {
      path: 'chnageforgotpassword', 
      component: ForgetPasswordChangeComponent 
    },
    
    {
      path: '',
      component: MainComponent, 
      canActivate: [AuthGuard],
      children: [{
        path: 'dashboard', loadChildren: ()=> 
        import('./dashboard/dashboard.module').then(m => m.DashboardModule)
        // canActivate: [ScormloginGuard]
      },{
        // path: 'master',
        // loadChildren: './master/master.module#MasterModule'
        path: 'master', loadChildren: ()=> 
        import('./master/master.module').then(m => m.MasterModule)
      },{
        // path: 'user',
        // loadChildren: './user/user.module#UserModule'
        path: 'user', loadChildren: ()=> 
        import('./user/user.module').then(m => m.UserModule)
      },{
        // path: 'student',
        // loadChildren: './student/student.module#StudentModule'
        path: 'student', loadChildren: ()=> 
        import('./student/student.module').then(m => m.StudentModule)
      },{
        path: 'pages',
        loadChildren: './blank/blank.module#BlankModule'
      },
      {
        // path:'profile',
        // component:ProfileComponent
        path: 'profile', loadChildren: ()=> 
        import('./profile/profile.module').then(m => m.profileModule)
      },
      {
        // path: 'dailyactivity',
        // loadChildren: './dailyactivity/dailyactivity.module#DailyactivityModule'
        path: 'dailyactivity', loadChildren: ()=> 
        import('./dailyactivity/dailyactivity.module').then(m => m.DailyactivityModule)
      },
      {
        // path: 'institution',
        // loadChildren: './institution/institution.module#InstitutionModule'
        path: 'institution', loadChildren: ()=> 
        import('./institution/institution.module').then(m => m.InstitutionModule)
      },{
        // path: 'instituteadmin',
        // loadChildren: './institute-admin/institute-admin.module#InstituteAdminModule'
        path: 'instituteadmin', loadChildren: ()=> 
        import('./institute-admin/institute-admin.module').then(m => m.InstituteAdminModule)
      },{
        // path: 'location',
        // loadChildren: './location/location.module#LocationModule'
        path: 'location', loadChildren: ()=> 
        import('./location/location.module').then(m => m.LocationModule)
      },{
        path: 'grade',
        loadChildren: './grade/grade.module#GradeModule'
      },{
        path: 'board',
        loadChildren: './board/board.module#BoardModule'
      },
      {
        // path: 'qualifications',
        // loadChildren: './qualification/qualification.module#QualificationModule',
        path: 'qualifications', loadChildren: ()=> 
        import('./qualification/qualification.module').then(m => m.QualificationModule)
      },
      {
        // path: 'subjects',
        //  loadChildren: './subject-categories/subject-categories.module#SubjectCategoriesModule',
        path: 'subjects', loadChildren: ()=> 
        import('./subject-categories/subject-categories.module').then(m => m.SubjectCategoriesModule)
      },
      {
        path: 'flashcard',
        loadChildren: './flashcard/flashcard.module#FlashcardModule',
      },
      {
        // path: 'appreciationcard',
        // loadChildren: './appreciationcard/appreciationcard.module#AppreciationCardModule'
        path: 'appreciationcard', loadChildren: ()=> 
        import('./appreciationcard/appreciationcard.module').then(m => m.AppreciationCardModule)
      },{
        // path: 'acadmeiccalendar',
        // loadChildren: './academiccalendar/academiccalendar.module#AcademicCalendarModule'

        path: 'acadmeiccalendar', loadChildren: ()=> 
        import('./academiccalendar/academiccalendar.module').then(m => m.AcademicCalendarModule)
      },
      {
        // path: 'feepayment',
        // loadChildren: './feepayment/feepayment.module#FeePaymentModule'
        
        path: 'feepayment', loadChildren: ()=> 
        import('./feepayment/feepayment.module').then(m => m.FeePaymentModule)
      },{
        // path: 'announcement',
        // loadChildren: './announcement/announcement.module#AnnouncementModule'
        path: 'announcement', loadChildren: ()=> 
        import('./announcement/announcement.module').then(m => m.AnnouncementModule)
      },
      {
        // path: 'chat',
        // loadChildren: './chat/chat.module#ChatModule'

        path: 'teacherdailyactivity', loadChildren: ()=> 
        import('./teacher-class-activity/teacher-class-activity.module').then(m => m.TeacherClassModule)
      },

      {
        // path: 'chat',
        // loadChildren: './chat/chat.module#ChatModule'

        path: 'teacherchat', loadChildren: ()=> 
        import('./teacher-chat/teacher-chat.module').then(m => m.TeacherChatModule)
      },

      {
        // path: 'chat',
        // loadChildren: './chat/chat.module#ChatModule'

        path: 'chat', loadChildren: ()=> 
        import('./chat/chat.module').then(m => m.ChatModule)
      },
      // { 
      //   path:'profile',
      //   component:ProfileComponent
      // },
      {
        // path:'images',
        // loadChildren: './images/images.module#ImagesModule'
        path: 'images', loadChildren: ()=> 
        import('./images/images.module').then(m => m.ImagesModule)
      },{
        // path:'timetable',
        // loadChildren: './timetable/timetable.module#TimetableModule'
        path: 'timetable', loadChildren: ()=> 
        import('./timetable/timetable.module').then(m => m.TimetableModule)

      },
      {
        // path:'studymaterial',
        // loadChildren: './studymaterial/studymaterial.module#StudyMaterialModule'
        path: 'studymaterial', loadChildren: ()=> 
        import('./studymaterial/studymaterial.module').then(m => m.StudyMaterialModule)
      },
      {
        // path: 'holidaycalendar',
        // loadChildren: './holidaycalendar/holidaycalendar.module#HolidaycalendarModule'
        path: 'holidaycalendar', loadChildren: ()=> 
        import('./holidaycalendar/holidaycalendar.module').then(m => m.HolidaycalendarModule)
        
      },
      {
        // path: 'markattendance',
        // loadChildren: './markattendance/markattendance.module#MarkattendanceModule'
        path: 'markattendance', loadChildren: ()=> 
        import('./markattendance/markattendance.module').then(m => m.MarkattendanceModule)
      },
      {
        // path: 'masterflashcardconfig',
        // loadChildren: './masterflashcardconfig/masterflashcardconfig.module#MasterflashcardconfigModule',
        path: 'masterflashcardconfig', loadChildren: ()=> 
        import('./masterflashcardconfig/masterflashcardconfig.module').then(m => m.MasterflashcardconfigModule)
      },
       {
        path: 'masterflashcardsubject',
        loadChildren: './masterflashcardsubject/masterflashcardsubject.module#MasterflashcardsubjectModule',
      },
      {
        // path: 'reportcard',
        // loadChildren: './reportcard/reportcard.module#ReportcardModule',
        path: 'reportcard', loadChildren: ()=> 
        import('./reportcard/reportcard.module').then(m => m.ReportcardModule)
      },
      {
        // path: 'reportcard',
        // loadChildren: './reportcard/reportcard.module#ReportcardModule',
        path: 'fee', loadChildren: ()=> 
        import('./fee-report/fee-report.module').then(m => m.FeeReportModule)
      },
      {
        // path: 'reportcard',
        // loadChildren: './reportcard/reportcard.module#ReportcardModule',
        path: 'transaction', loadChildren: ()=> 
        import('./transaction-report/transaction-report.module').then(m => m.TransactionReportModule)
      },
     /* {
        path:'feereport',
        component: FeeReportComponent,
      canActivate: [AuthGuard],
      },*/
    ],
    },
    {
      path: '',
      component: AuthComponent,
      children: [{
    
        path: 'session', loadChildren: ()=> 
        import('./session/session.module').then(m => m.SessionDemoModule)
      }]
    },
 
];
@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: []
})
export class RoutingModule { }       

